import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { tap, catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable()
export class AuthenticationService {

    SESSION_KEY_NAME = 'session_key';
    USER_DATA_KEY_NAME = 'user'
    constructor(
        private http: HttpClient,
        private router: Router) {
    }

    login(username: string, password: string) {
        let headers = new HttpHeaders({
            'No-Loader': 'true',
        });
        return this.http.post(environment.API.BASE_URL + '/auth/user', { "username": username, "password": password }, { headers: headers })
            .pipe(tap((response: any) => {
                this.setSessionData(response);
            }));
    }

    setSessionData(sessionData: any) {
        localStorage.setItem(this.SESSION_KEY_NAME, sessionData.token);
        sessionData.token = null;
        localStorage.setItem(this.USER_DATA_KEY_NAME, JSON.stringify(sessionData));
    }

    updateToken(token:any)
    {
        localStorage.setItem(this.SESSION_KEY_NAME, token);
    }

    getSessionKey() {
        return localStorage.getItem(this.SESSION_KEY_NAME)
    }

    getUser() {
        return JSON.parse(localStorage.getItem(this.USER_DATA_KEY_NAME));
    }

    logout() {
        this.clearLocalStorage();
        this.redirectToLogin();
    }

    clearLocalStorage() {
        localStorage.clear();
    }

    directToInternalPage(redirectUrl?: string) {
      console.log('redirect', redirectUrl);
        if (redirectUrl) {
            this.router.navigate([redirectUrl]);
        }
        else {
            this.router.navigate(['/']);
        }
    }

    redirectToLogin(redirectUrl?: string) {
      console.log('urllllllll====>' + redirectUrl);
        if (redirectUrl) {
            this.router.navigate(['/Login'], { queryParams: { redirecturl: redirectUrl } })
        }
        else {
            this.router.navigate(['/Login']);
        }
    }

}
