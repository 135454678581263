import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  // {
  //   id: 'sa-api-call',
  //   title: 'SA API Call',
  //   type: 'collapse',
  //   icon: 'fas fa-cogs',
  //   children: [
  //     {
  //       id: 'view-api-call',
  //       title: 'View API Calls',
  //       breadcrumb: 'API',
  //       type: 'item',
  //       url: '/Datasets/Logs'
  //     }
  //   ]
  // },
  {
    id: 'project',
    title: 'Projects',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'search-projects',
        title: 'Search Projects',
        breadcrumb: 'Search Project',
        type: 'item',
        url: '/Project/SearchProjects'
      },
      {
        id: 'create-project',
        title: 'Create Project',
        breadcrumb: 'New Project',
        type: 'item',
        url: '/Project/NewProject'
      }
    ]
  },
  {
    id: 'externalats',
    title: 'External ATS',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'pageup',
        title: 'PageUp',
        type: 'collapse',
        icon: '',
        children: [
          {
            id: 'pageup-search-candidates',
            title: 'Search Candidate',
            breadcrumb: 'Search Candidate',
            type: 'item',
            url: '/ExternalATS/PageUpSearchCandiate'
          },
          {
            id: 'pageup-credential',
            title: 'Credentials',
            breadcrumb: 'Credential',
            type: 'item',
            url: '/ExternalATS/PageUpCredential'
          }
        ]
      },
      {
        id: 'externalats-keyword',
        title: 'Keyword Adder',
        breadcrumb: 'Keyword Adder',
        type: 'item',
        url: '/ExternalATS/Keyword'
      }
    ]
  },
  {
    id: 'report',
    title: 'NR Reports',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'search-reports',
        title: 'Search By Tags',
        breadcrumb: 'Search Reports By Tags',
        type: 'item',
        url: '/Report/SearchReports'
      },
      {
        id: 'search-reports-client',
        title: 'Search By Client',
        breadcrumb: 'Search Reports By Client',
        type: 'item',
        url: '/Report/SearchReportsByClient'
      },
      {
        id: 'norm-update-log',
        title: 'Framework Norm Update',
        type: 'collapse',
        children: [
          {
            id: 'search-framework',
            title: 'Search Framework',
            breadcrumb: 'Search Framework',
            type: 'item',
            url: '/NormUpdateLog/SearchFramework'
          },
          {
            id: 'view-norm-update-log',
            title: 'View Logs',
            breadcrumb: 'View Logs',
            type: 'item',
            url: '/NormUpdateLog/ViewLogs'
          }
        ]
      }
    ]
  },
  {
    id: 'candidates',
    title: 'Candidates',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'view-candidates',
        title: 'View Reports',
        breadcrumb: 'View Reports',
        type: 'item',
        url: '/Candidates/ViewCandidates'
      },
      {
        id: 'remove-candidate',
        title: 'Remove Candidate',
        breadcrumb: 'Remove Candidate',
        type: 'item',
        url: '/Candidates/RemoveCandidate'
      },
      {
        id: 'initialize-instrument',
        title: 'Initialize Instrument',
        breadcrumb: 'Initialize Instrument',
        type: 'item',
        url: '/Candidates/InitializeInstrument'
      }
    ]
  },
  {
    id: 'users',
    title: 'Users',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'view-users',
        title: 'View Users',
        breadcrumb: 'View Users',
        type: 'item',
        url: '/Users/UserList'
      }
      // {
      //   id: 'tag',
      //   title: 'Tag Management',
      //   breadcrumb: 'Tag',
      //   type: 'item',
      //   url: '/Users/Tag'
      // }
      // {
      //   id: 'new-user',
      //   title: 'New User',
      //   breadcrumb: 'New User',
      //   type: 'item',
      //   url: '/Users/NewUser'
      // }
    ]
  },
  {
    id: 'usageReport',
    title: 'Usage Report',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'extract-usage-report',
        title: 'Usage Extracts',
        breadcrumb: 'Usage Extracts',
        type: 'item',
        url: '/Report/UsageReport'
      }
  ]
  },
  {
    id: 'norm-groups',
    title: 'SA Integration',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'upload-norm-groups',
        title: 'RIN Management',
        breadcrumb: 'RIN Management',
        type: 'item',
        url: '/NormGroups/UploadNormGroups'
      },
      {
        id: 'instrument-details-update',
        title: 'Instrument Details',
        breadcrumb: 'Instrument Details',
        type: 'item',
        url: '/NormGroups/InstrumentDetails'
      },
      {
        id: 'view-api-call',
        title: 'View API Calls',
        breadcrumb: 'API',
        type: 'item',
        url: '/Datasets/Logs'
      }
      // {
      //   id: 'upload-special-sa-dimensions',
      //   title: 'Upload Special SA Dimensions',
      //   breadcrumb: 'Upload Special SA Dimensions',
      //   type: 'item',
      //   url: '/NormGroups/UploadSpecialSADimensions'
      // }
    ]
  },
  // {
  //   id: 'norm-update-log',
  //   title: 'Framework Norm Update',
  //   type: 'collapse',
  //   icon: 'fas fa-cogs',
  //   children: [
  //     {
  //       id: 'search-framework',
  //       title: 'Search Framework',
  //       breadcrumb: 'Search Framework',
  //       type: 'item',
  //       url: '/NormUpdateLog/SearchFramework'
  //     },
  //     {
  //       id: 'view-norm-update-log',
  //       title: 'View Logs',
  //       breadcrumb: 'View Logs',
  //       type: 'item',
  //       url: '/NormUpdateLog/ViewLogs'
  //     }
  //   ]
  // },
  {
    id: 'merit-list',
    title: 'Merit List',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      // {
      //   id: 'set-merit-list',
      //   title: 'Set Merit List',
      //   breadcrumb: 'Setting',
      //   type: 'item',
      //   url: '/MeritList/Setting'
      // }
      {
        id: 'set-system-merit-list',
        title: 'System Level Setting',
        breadcrumb: 'System Level Setting',
        type: 'item',
        url: '/MeritList/SettingSystem'
      }
    ]
  },
  {
    id: 'monitoring',
    title: 'Monitoring',
    type: 'collapse',
    icon: 'fas fa-cogs',
    children: [
      {
        id: 'events-tracking',
        title: 'Events Tracking',
        breadcrumb: 'Events Tracking',
        type: 'item',
        url: '/NotificationEvents/EventList'
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
