import {Component, Input, OnDestroy, Inject, ViewEncapsulation, OnInit} from '@angular/core';
import {Spinkit} from './spinkits';
import {Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import { LoaderService } from 'src/app/services/system/loader-service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: [
        './spinner.component.scss',
        './spinkit-css/sk-line-material.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit, OnDestroy {
    public isSpinnerVisible = true;
    public Spinkit = Spinkit;
    @Input() public backgroundColor = '#2196f3';
    @Input() public spinner = Spinkit.skLine;
    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private loaderService: LoaderService
        ) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.isSpinnerVisible = true;
                } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                    this.isSpinnerVisible = false;
                }
                if (this.router.routerState.snapshot.url.indexOf('/RetryPipeline') > 0) {
                  this.isSpinnerVisible = false;
                }
            }, () => {
                this.isSpinnerVisible = false;
            });
    }

    ngOnInit() {
        this.loaderService.currentMessage.subscribe(message => {
            this.isSpinnerVisible = message;
            if (this.router.routerState.snapshot.url.indexOf('/RetryPipeline') > 0) {
              this.isSpinnerVisible = false;
          }
        });
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
    }
}
