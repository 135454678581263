import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'Project/SearchProjects',
        pathMatch: 'full'
      },
      {
        path: 'Datasets',
        loadChildren: () => import('./pages/datasets/datasets.module').then(module => module.DatasetsModule)
      },
      {
        path: 'Project',
        loadChildren: () => import('./pages/project/project.module').then(module => module.ProjectModule)
      },
      {
        path: 'Report',
        loadChildren: () => import('./pages/report/report.module').then(module => module.ReportModule)
      },
      {
        path: 'Candidates',
        loadChildren: () => import('./pages/candidates/candidates.module').then(module => module.CandidatesModule)
      },
      {
        path: 'Users',
        loadChildren: () => import('./pages/users/users.module').then(module => module.UsersModule)
      },
      {
        path: 'NormGroups',
        loadChildren: () => import('./pages/norm-groups/norm-groups.module').then(module => module.NormGroupsModule)
      },
      {
        path: 'NormUpdateLog',
        loadChildren: () => import('./pages/norm-update-log/norm-update-log.module').then(module => module.NormUpdateLogModule)
      },
      {
        path: 'MeritList',
        loadChildren: () => import('./pages/merit-list/merit-list.module').then(module => module.MeritListModule)
      },
      {
        path: 'ExternalATS',
        loadChildren: () => import('./pages/external-ats/external-ats.module').then(module => module.ExternalAtsModule)
      },
      {
        path: 'NotificationEvents',
        loadChildren: () => import('./pages/notification-events/notification-events.module').then(module => module.NotificationEventsModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
